.ant-radio-wrapper span {
  color: white !important;
}

.ant-progress {
  margin: 0 !important;
}

.ant-progress-outer {
  height: 0 !important;
}

.ant-progress-inner {
  border-radius: 0 !important;
  height: 4px !important;
}

.ant-progress-bg {
  border-radius: 0 !important;
}

.carousel.carousel-slider .control-arrow {
  display: none;
}

.carousel .carousel-status {
  display: none;
}

.splide_image {
  height: 9.6rem;
  width: 500px;
}

.responsive-image {
  width: 510px;
  height: 80%;
  max-width: 100vw;
}

.ant-tabs-tab-btn {
  padding: 23px 10px;
  font-weight: 300;
  color: #ccc;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  border-radius: 4px;
  /* background: rgb(205, 159, 21); */
}

.antd-notices-modal.ant-modal .ant-modal-footer {
  margin-top: 0px;
}
.antd-notices-modal.ant-modal .ant-modal-content {
  padding: 0px !important;
}
.antd-notices-modal.ant-modal {
  transform: translateY(-50%) !important;
  top: 50%;
}
.antd-promotion-modal.ant-modal {
  transform: translateY(-50%) !important;
  top: 50%;
}

.content-box {
  min-height: 500px;
}

.content-box .header-tabs {
  border-bottom: 1px solid rgb(205, 159, 21);
  border-top: 1px solid rgb(205, 159, 21);
  margin-top: 1rem;
  margin-bottom: 8px;
}


.provider-feature-title {
  text-align: center;
  color: transparent !important;
  background: linear-gradient(45deg, #ffde7c, #ffee6b);
  -webkit-background-clip: text;
  background-clip: text;
  text-transform: uppercase;
  letter-spacing: .6rem !important;
  margin-left: .4rem !important;
}

.provider-title {
  text-align: center;
  color: transparent !important;
  background: linear-gradient(45deg, #ffde7c, #ffee6b);
  -webkit-background-clip: text;
  background-clip: text;
  text-transform: uppercase;
  letter-spacing: .6rem !important;
  margin-left: .4rem !important;
}

.letter {
  animation: glow 2s ease-in-out infinite alternate;
}

@keyframes glow {
  0% {
    text-shadow: 0 0 10px rgba(255, 205, 68, 0.8), 0 0 20px rgba(255, 205, 68, 0.8), 0 0 30px rgba(255, 205, 68, 0.8);
  }
  100% {
    text-shadow: 0 0 20px rgba(255, 218, 119, 1), 0 0 40px rgba(255, 218, 119, 0.8), 0 0 60px rgba(255, 218, 119, 0.6);
  }
}

.underline-box {
  display: flex;
  justify-content: center;
  column-gap: 1rem;
  margin-bottom: 12px;
  margin-top: 8px;
}

.underline-box .left {
  height: 2px;
  background: #ffee6b;
  flex-basis: 30%;
}
.underline-box .right {
  height: 2px;
  background: #ffee6b;
  flex-basis: 30%;
}

.ant-modal-content {
  width: 90%;
  background: black !important;
  border: 2px solid #ffc107;
  margin: 0 auto;
  position: relative;
}

.checkin-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 8px;
  padding-bottom: 12px;
  background: rgba(0,0,0,.8);
}

.checkin-container .title {
  color: white !important;
  font-weight: 500;
  font-size: .9rem;
}

.day-container {
  display: flex;
  gap: 8px;
  margin-top: 6px;
  width: 100%;
  overflow-x: auto;
}
.day-container .day-box {
  width: 25%;
  border-width: 1px;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2C2C2C;
  flex-direction: column;
  border-radius: 7px;
  padding: 10px;
  flex-shrink: 0;
}
.day-container .day-box .day-inner-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .day-inner-box.checked {
  background: rgb(78, 131, 0);
} */
.day-box .day {
  font-size: .9rem;
  color: #fff !important;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  margin-bottom: 5px !important;
}
.day-box .daily_claim{
  outline: none;
  border: none;
  cursor: pointer;
  padding: 2px 15px;
  text-transform: uppercase;
  border-radius: 20px;
  font-size: 12px;
  font-weight: bold;
}

.promo-horizontal-scroll-list {
  width: 100%;
  overflow: hidden;
}

.promo-horizontal-scroll-list .promo-title {
  padding: 8px;
  padding-bottom: 0;
  margin-top: 8px;
}

.scrollable-container {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  white-space: nowrap;
  padding: 10px;
}

.scrollable-content {
  display: flex;
}


.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 items in a row */
  gap: 8px;
}

.grid-item {
  text-align: center;
}

.css-1nchzcb{
  max-width: 100% !important;
}

.click_to_claim {
  padding-bottom: 60px;
}
.click_to_claim .center_wrapper{
  overflow: hidden;
  position: relative;
  max-width: 900px;
  margin: 0 auto;
  padding: 0 10px 10px 10px;
}
.click_to_claim .claim_box{
  /* background: black; */
  background-size: 100% 100%!important;
  padding: 15px ;
  margin: 0;
  border-radius: 15px;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  /* padding-bottom: 62px; */
}
.claim_box button{
  border: 2px solid #FFC107;
  background-color: #AD711D;
  color: black;
  text-transform: uppercase;
  font-weight: bolder;
  padding: 2px 2rem;
}
.center_wrapper .packet{
  text-align: center;
  color: white;
  margin: 10px 10px 0 0;
}
.center_wrapper .packet .claim_box p{
  line-height: 18px;
}
.center_wrapper .packet .claim_box button{
  padding: 2px 1rem;
}
.non_active{
  position: relative;
}
.non_active::before{
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0,0,0,.6);
  z-index: 1;
}
.packet-grp p{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px !important;
  /* border-radius: 7px !important; */
  background-image: linear-gradient(#F4D8FE, #CD56FA) ;
  /* border: 2px solid white; */
  text-transform: uppercase;
  height: 30px;
}
.pagination a{
  font-size: 13px;
}

#history2{
  overflow: hidden;
  font-size: 13px;
}
#history2 .tabs{
  overflow: hidden;
  padding: 5px 5px 0;
}
#history2 .tabs .tab{
  float: left;
  text-align: center;
  background: #37474F;
  padding: 10px 15px;
  cursor: pointer;
  margin: 5px 0 0 5px;
  border-radius: 5px 5px 0 0;
  font-weight: 700;
}
#history-transaction{
  text-transform: capitalize;
  overflow: hidden;
}
#history-transaction table{
  width: 100%;
  line-height: 1.5;
  table-layout: fixed;
}
#history-transaction table>tbody>tr:first-child>td{
  padding: 5px 8px 3px;
  background: #1d2531;
  font-weight: 700;
}
#history-transaction table>tbody>tr>td{
  padding: 8px;
  vertical-align: top;
  /* border: 1px solid #ccc; */
}

/* Bet History2 */
#history-bet .note{
  padding: 5px;
  line-height: 1;
}
#history-bet table td{
  padding: 5px;
  vertical-align: top;
  border-width: 1px 0 1px 1px;
  border-style: solid;
  word-break: break-word;
}
#history-bet table td:first-child{
  border-width: 1px 0;
}
#history-bet table tr:first-child td{
  background: #1d2531;
  font-weight: 700;
}
.subline{
  display: flex;
  flex-wrap: wrap;
}
.subline .cell{
  padding: 5px;
}
.subline_image{
  width:100%;
}
.telcoPay_popup{
  width: 100%;
  padding: 10px;
  padding-bottom: 0px;
}
.telcoPay_popup .telcoPay_header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.telcoPay_popup p{
  font-weight: 500;
  font-size: 15px;
}
.telcopay_tabs{
  border: 1px solid #596FD6;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}
.telcopay_tabs a{
  border-radius: 5px;
  padding: 10px 25px;
  display: inline-block;
  color: white;
}
.coupon-code-box input{
  width: 32%;
  border-radius: 5px;
  text-align: center;
}
.coupon-code-box span{
  color: white;
    font-size: 24px;
    margin: 0 5px;
} 